
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "settings",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Account Settings");
    });

    return {};
  },
});
